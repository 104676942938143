import React from "react";

import AccountCompleteSection from "../components/AccountCompleteSection";

function AccountCompletePage(props) {
  return (
    <AccountCompleteSection
      textColor="white"
      variant="dark"
      title="AccountComplete"
      subtitle=""
    />
  );
}

export default AccountCompletePage;
