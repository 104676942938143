import React, { useState } from "react";

import AlphaWhaleSection from "../components/AlphaWhaleSection";
import { useNFT } from '../hooks/useContract';

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function AlphaWhalePage(props) {
  const { id } = props.match.params;
  const [contract] = useState(useNFT(NFT_CONTRACT_ADDRESS));

  return (
    <>
      {contract ? (
        <AlphaWhaleSection
          textColor="white"
          variant="dark"
          contract={contract}
          id={id}
        />
      ) : null}
    </>
  );
}

export default AlphaWhalePage;
