import React, { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Skeleton from '@mui/material/Skeleton';

import SectionHeader from "../SectionHeader";
import RewardsComponent from './RewardsComponent';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT } from '../../hooks/useContract';
import { Link } from "../../util/router.js";

import "./WalletComponent.scss";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function WalletComponent(props) {
  const { active, account } = useActiveWeb3React();
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [alphies, setAlphies] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (nftContract && active && account) {
      getAlphiesByAccount();
    }
  }, [nftContract, active, account]);


  const fetchNFTFromTokenURI = async (id) => {
    try {
      const tokenURI = await nftContract.tokenURI(id);
      const response = await fetch(tokenURI, { method: "GET", mode: 'cors', headers: { 'Content-Type': 'application/json',}})
      return response.json();
    } catch (error) {
      console.log('error=====>>>>>', error);
    }
  }

  const getAlphiesByAccount = async () => {
    const tokenIds = await nftContract.walletOfOwner(account);
    setTotalLength(tokenIds.length);
    const alphies = [];
    for (let tokenId of tokenIds) {
      const id = formatUnits(tokenId, 0);
      const token = await fetchNFTFromTokenURI(id);
      alphies.push({
        ...token,
        id,
      });
      timeout(300);
    }

    setAlphies(alphies);
    setIsPageLoading(false);
  }

  return (
    <>
      {account ? (
        <Container className="text-center">
          <SectionHeader
            className="header-font above-fold-header"
            title="My Wallet"
            size={1}
          />
          <Row className="text-left align-items-center" style={{ padding: '0px'}}>
            <RewardsComponent />
          </Row>
          {!isPageLoading && alphies.length === totalLength ? (
            <>
              <Row className="d-flex align-items-center" style={{ marginTop: '40px' }}>
                <h2 className="text-left">NFTs ({alphies.length})</h2>
              </Row>
              <Row className="d-flex align-items-center" style={{ marginTop: '5px' }}>
                {alphies && alphies.length === totalLength && alphies.map((token, index) => (
                  <div key={token.dna}>
                    {token ? (
                      <div key={token.dna}>
                        <Col md={3} className="text-center" style={{ paddingLeft : '0px', marginBottom: '30px' }}>
                          <Card style={{ width: '18rem', border: 'transparent' }}>
                            <Link to={`/alphies/${token.id}`} target="_blank">
                              <Card.Img variant="top" src={token.image} style={{ height: 'auto', width: '100%' }} />
                            </Link>
                            <Card.Body>
                              <Link to={`/alphies/${token.id}`} target="_blank">
                                <Card.Title style={{ color: 'black' }}>{token.name}</Card.Title>
                              </Link>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                    ) : null}
                  </div>
                ))}
              </Row>
            </>
          ) : (
            <>
              <Row className="d-flex align-items-center" style={{ marginTop: '40px' }}>                
                <h2 className="text-left">NFTs</h2>
              </Row>
              <Row className="" style={{ marginTop: '5px' }}>
                <div>
                  <Col md={3} className="text-center" style={{ paddingLeft : '0px', marginBottom: '20px' }}>
                    <Card style={{ width: '18rem' }}>
                      <Card.Body>
                        <h4>
                          <Skeleton animation="wave" width="70%" />
                        </h4>
                        <p>
                          <Skeleton animation="wave" width="40%" />
                          <Skeleton animation="wave" width="30%" />
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
                <div>
                  <Col md={3} className="text-center" style={{ paddingLeft : '0px', marginBottom: '20px' }}>
                    <Card style={{ width: '18rem' }}>
                      <Card.Body>
                        <h4>
                          <Skeleton animation="wave" width="70%" />
                        </h4>
                        <p>
                          <Skeleton animation="wave" width="40%" />
                          <Skeleton animation="wave" width="30%" />
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
                <div>
                  <Col md={3} className="text-center" style={{ paddingLeft : '0px', marginBottom: '20px' }}>
                    <Card style={{ width: '18rem' }}>
                      <Card.Body>
                        <h4>
                          <Skeleton animation="wave" width="70%" />
                        </h4>
                        <p>
                          <Skeleton animation="wave" width="40%" />
                          <Skeleton animation="wave" width="30%" />
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </Row>
            </>
          )}
        </Container>
      ) : (
        <Col xs={12} className="text-center">
          <h1>You're not allowed to be here</h1>
        </Col>
      )}

    </>
  );
}

export default WalletComponent;