import React, { useEffect, useState } from "react";
import { formatUnits, parseEther } from "@ethersproject/units";
import { Form, Col, Button } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import csvtojsonV2 from 'csvtojson';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT, useDevWallet, useERC20 } from '../../hooks/useContract';

import "./AdminComponent.scss";

const DEV_WALLET_CONTRACT_ADDRESS = process.env.REACT_APP_DEV_WALLET_CONTRACT_ADDRESS;
const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
const WETH_CONTRACT_ADDRESS = process.env.REACT_APP_WETH_ADDRESS;

function AdminComponent(props) {
  const { library, active, account } = useActiveWeb3React();
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [devWalletContract] = useState(useDevWallet(DEV_WALLET_CONTRACT_ADDRESS));
  const [wethContract] = useState(useERC20(WETH_CONTRACT_ADDRESS));
  const [owner, setOwner] = useState();
  const [WETHBalance, setWETHBalance] = useState(0);
  const [ETHBalance, setETHBalance] = useState(0);
  const [ETHBalanceInNFT, setETHBalanceInNFT] = useState(0);
  const [founderToEquity, setFounderToEquity] = useState(0);
  const [addressToPendingETHWithdrawal, setAddressToPendingETHWithdrawal] = useState(0);
  const [addressToPendingWETHWithdrawal, setAddressToPendingWETHWithdrawal] = useState(0);

  const [mintPreValue, setMintPreValue] = useState(false);
  const [mintPublicValue, setMintPublicValue] = useState(false);
  const [moonTokenAddress, setMoonTokenAddress] = useState(false);
  const [genesisWhalesAmount, setGenesisWhalesAmount] = useState(0);

  const [ETHAmountToClaim, setETHAmountToClaim] = useState(0);
  const [wETHAmountToClaim, setWETHAmountToClaim] = useState(0);

  const { addToast } = useToasts();

  useEffect(() => {
    if (account) {
      const founderToEquity = getFounderToEquity();
      if (founderToEquity > 0) {
        getAddressToPendingWithdrawal();
        getAddressToPendingWETHWithdrawal();
      }
    }
  }, [account]);

  useEffect(() => {
    if (devWalletContract && wethContract) {
      getDevWalletBalance();
      getDevWalletWETHBalance();
      getFounderToEquity();
      getAddressToPendingWithdrawal();
      getAddressToPendingWETHWithdrawal();
      getMintValue();

      getMoonToken();

      devWalletContract.on('PaymentReceived', async (from, amount) => {
        getDevWalletBalance();
        getDevWalletWETHBalance();
        const message = `payment from ${from} for Amount: ${formatUnits(amount, 18)}`;
        addToast(message, {
          appearance: 'info',
          autoDismiss: true,
        });
      });

      devWalletContract.on('TransferERC20Sent', async (from, to, amount) => {
        getDevWalletBalance();
        getDevWalletWETHBalance();
        const message = `payment from ${from} to ${to} for Amount: ${formatUnits(amount, 18)}`;
        addToast(message, {
          appearance: 'info',
          autoDismiss: true,
        });
      });
    }
  }, [devWalletContract, wethContract])

  useEffect(() => {
    if (nftContract && active) {
      getOwner();
      getETHBalanceInNFT();
      getReserveAmount();
    }
  }, [nftContract, active]);


  const getReserveAmount = async () => {
    const genesisWhalesAmount = await nftContract.GENESIS_RESERVE();
    setGenesisWhalesAmount(genesisWhalesAmount)
  }

  const getMintValue = async () => {
    const preMintActive = await nftContract.preMintActive();
    const publicMintActive = await nftContract.publicMintActive();

    setMintPreValue(preMintActive)
    setMintPublicValue(publicMintActive)
  };

  const getMoonToken = async () => {
    const moonToken = await nftContract.MoonToken();
    setMoonTokenAddress(moonToken)
  };

  const togglePreMintActive = async () => {
    const tx = await nftContract.togglePreMintActive();
    await tx.wait();
    await getMintValue();
  }

  const togglePublicMintActive = async () => {
    const tx = await nftContract.togglePublicMintActive();
    await tx.wait();
    await getMintValue();
  }

  const toggleBetweenPreAndPublicActive = async () => {
    const tx = await nftContract.toggleBetweenPreAndPublicActive();
    await tx.wait();
    await getMintValue();
  }

  const getETHBalanceInNFT = async () => {
    const ETHBalanceInNFT = await library.getBalance(nftContract.address);
    setETHBalanceInNFT(formatUnits(ETHBalanceInNFT, 18));
  }

  const getDevWalletBalance = async () => {
    const devWalletBalance = await library.getBalance(devWalletContract.address);
    setETHBalance(formatUnits(devWalletBalance, 18));
  }

  const getDevWalletWETHBalance = async () => {
    const WETHdevWalletBalance = await wethContract.balanceOf(DEV_WALLET_CONTRACT_ADDRESS);
    setWETHBalance(formatUnits(WETHdevWalletBalance, 18));
  }

  const getFounderToEquity = async () => {
    const founderToEquity = await devWalletContract.founderToEquity(account);
    setFounderToEquity(formatUnits(founderToEquity, 0));

    return formatUnits(founderToEquity, 0);
  }

  const getAddressToPendingWithdrawal = async () => {
    const addressToPendingWithdrawal = await devWalletContract.addressToPendingWithdrawal(account);

    setAddressToPendingETHWithdrawal(formatUnits(addressToPendingWithdrawal, 18));
    setETHAmountToClaim(formatUnits(addressToPendingWithdrawal, 18));
  }

  const getAddressToPendingWETHWithdrawal = async () => {
    const pendingWETHBalance = await devWalletContract.getPendingWETHBalance();
    setAddressToPendingWETHWithdrawal(formatUnits(pendingWETHBalance, 18));
    setWETHAmountToClaim(formatUnits(pendingWETHBalance, 18));
  }

  const getOwner = async () => {
    const owner = await nftContract.owner();
    setOwner(owner);
  }

  const withdrawBalanceToDev = async () => {
    try {
      await nftContract.withdrawBalanceToDev();
    } catch (error) {
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  const devClaimAndSplitBalance = async () => {
    try {
      const tx = await devWalletContract.withdraw(parseEther(ETHAmountToClaim));
      await tx.wait();
      await getAddressToPendingWithdrawal();
      await getDevWalletBalance();
    } catch (error) {
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  const devClaimSplitWETH = async () => {
    try {
      const tx = await devWalletContract.releaseWETH(parseEther(wETHAmountToClaim));
      await tx.wait();
      await getAddressToPendingWETHWithdrawal();
      await getDevWalletWETHBalance();
    } catch (error) {
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <>
      {account && (founderToEquity > 0 || owner === account) ? (
        <>
          {account && owner === account ? (
            <>
              <Col xs={6}>
                <h4><u>NFT Contract</u></h4>
                <div style={{ marginBottom: '20px' }}>
                  <Form onSubmit={async (e) => {
                    e.preventDefault();
                    await nftContract.setMoonToken(moonTokenAddress);
                  }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Moon Token Address
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Moon Token Address"
                        value={moonTokenAddress}
                        onChange={({target: { value }}) => setMoonTokenAddress(value)}
                      />
                    </Form.Group>
                    <Button size="sm" type="submit">
                      Update Moon Token Address
                    </Button>
                  </Form>
                </div>
                <div>
                  <Button
                    size="sm"
                    style={{ marginTop: '5px' }}
                    onClick={togglePreMintActive}
                  >
                    Mint Pre: {mintPreValue ? 'Active' : 'OFF'}
                  </Button>
                  <br />
                  <Button
                    size="sm"
                    style={{ marginTop: '5px' }}
                    onClick={togglePublicMintActive}
                  >
                    Mint Public: {mintPublicValue ? 'Active' : 'OFF'}
                  </Button>

                  <br />

                  <Button
                    size="sm"
                    style={{ marginTop: '5px' }}
                    onClick={toggleBetweenPreAndPublicActive}
                  >
                    Toggle Private to Public: <u>{mintPublicValue ? 'PUBLIC' : 'PRIVATE'}</u>
                  </Button>
                </div>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <Form onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                      const tx = await nftContract.reserveGenesisTokens(genesisWhalesAmount);
                      await tx.wait();
                      await getReserveAmount();
                    } catch (error) {
                      addToast(error.reason ? error.reason : error.message , {
                        appearance: 'error',
                        autoDismiss: true,
                      });
                    }
                  }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Reserve Genesis Whales
                      </Form.Label>
                      <Form.Control
                        placeholder="Amount of Genesis Whales"
                        type="number"
                        value={genesisWhalesAmount}
                        onChange={({target: { value }}) => setGenesisWhalesAmount(value)}
                      />
                    </Form.Group>
                    <Button size="sm" type="submit">
                      Reserve
                    </Button>

                    <p style={{ fontSize: '12px', marginTop: '5px', background: 'red', border: '1px solid white', padding: '5px' }}>*Only dev multisig can call</p>
                  </Form>
                </div>

                <br />
                <p>ETH Total: {ETHBalanceInNFT} ETH</p>
                <Button onClick={withdrawBalanceToDev} size="sm">
                  Withdraw Balance from NFT to Dev Wallet
                </Button>
                <p style={{ fontSize: '12px', marginTop: '5px', background: 'red', border: '1px solid white', padding: '5px' }}>*Only dev multisig can call</p>
              </Col>
              <br />
              <br />
            </>
          ) : null}
          <Col xs={6}>
            <h4><u>Dev Wallet</u></h4>
            <p>ETH Total: {ETHBalance} ETH</p>
            <p>WETH Total: {WETHBalance} WETH</p>

            <hr/>
            <h5><u>My Portion</u></h5>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                ETH Amount Left to Claim: {addressToPendingETHWithdrawal} ETH
              </Form.Label>
              <Form.Control
                placeholder="Enter ETH amount you want to claim"
                value={ETHAmountToClaim}
                onChange={({target: { value }}) => setETHAmountToClaim(value)}
              />
            </Form.Group>
            <Button onClick={devClaimAndSplitBalance} size="sm" style={{ marginRight: '20px' }}>
              Claim Your ETH
            </Button>

            <br />
            <br />

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                WETH Amount Left to Claim: {addressToPendingWETHWithdrawal} WETH
              </Form.Label>
              <Form.Control
                placeholder="Enter WETH amount you want to claim"
                value={wETHAmountToClaim}
                onChange={({target: { value }}) => setWETHAmountToClaim(value)}
              />
            </Form.Group>
            <Button onClick={devClaimSplitWETH} size="sm">
              Claim Your WETH
            </Button>
          </Col>
        </>
      ) : (
        <Col xs={12} className="text-center">
          <h1>You're not allowed to be here</h1>
        </Col>
      )}

    </>
  );
}

export default AdminComponent;