const ALPHIEMOON_BE_BASE_URL = process.env.REACT_APP_ALPHIEMOON_BE_BASE_URL;
const ALPHIEMOON_API_KEY = process.env.REACT_APP_ALPHIEMOON_API_KEY;

const GUILD_ID = process.env.REACT_APP_GUILD_ID;

export const getAccountByMoonWallet = async (moonWallet) => {
  if (!moonWallet) return false;

  try {
    const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/moon-wallet/${moonWallet}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ALPHIEMOON_API_KEY}`
      },
    };
    const res = await fetch(url, requestOptions);
    if (res.ok) return res.json();
    throw Error(res.statusText);
  } catch (error) {
    console.log('error->', error);
  }
}

export const getAccountByUserId = async (userId) => {
  if (!userId) return false;

  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account/${userId}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ALPHIEMOON_API_KEY}`
    },
  };
  const res = await fetch(url, requestOptions);
  if (res.ok) return res.json();
  throw Error(res.statusText);
}

export const createAccountFromWeb = async (moonWallet, wlWallet, userId) => {
  const url = `${ALPHIEMOON_BE_BASE_URL}/api/account`;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ALPHIEMOON_API_KEY}`
    },
    body: JSON.stringify({
      moonWallet,
      wlWallet,
      userId,
      serverId: GUILD_ID,
    }),
  };

  return fetch(url, requestOptions).then(t => {
    if (!t.ok) throw Error(t.statusText);
    return t;
  });
}
