import React, { useState } from "react";
import { scroller } from "react-scroll";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { Link } from "../../util/router";

import SectionHeader from "../SectionHeader";
import Section from "./Section";

import WalletModal from "../WalletModal";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';

import frankHomiesGang from "../../images/frankhomies-gang.png";

function MintSection(props) {
  const { active, error } = useActiveWeb3React();

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -250,
    });
  }


  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        style={{
          paddingBottom: '0px !important',
          background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
        }}
      >
        <Container style={{ padding: '100px 35px 0px' }}>          
          <>
            <SectionHeader
              className="text-center header-font above-fold-header"
              title={props.title}
              subtitle={props.subtitle}
              size={1}
            />
            <Row className="align-items-center">
              <>       
                <Col xs={12} className="text-center">
                    <div>
                      <Link to="/" onClick={() => scrollToSection('mintsection')}>  
                        <Button variant={props.buttonColor} onClick={() => scrollToSection('mintsection')} size="lg">
                          Let's Mint!
                        </Button>
                      </Link>
                    </div>
                </Col>
                <Col xs={12} className="text-center">
                  <Image src={frankHomiesGang} rounded width={400} className="img-fluid" />
                </Col>
              </>
            </Row>
          </>
        </Container>
      </Section>
    </>
  );
}

export default MintSection;
