import React, { useEffect, useState } from "react";
import { formatUnits, formatEther } from "@ethersproject/units";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import Spinner from "react-bootstrap/Spinner";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useMoonToken } from '../../hooks/useContract';
import { shorter } from "../../util/util";

import "./RewardsComponent.scss";

const MOONTOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_MOONTOKEN_CONTRACT_ADDRESS;

function RewardsComponent(props) {
  const { active, account } = useActiveWeb3React();
  const [moonTokenContract] = useState(useMoonToken(MOONTOKEN_CONTRACT_ADDRESS));
  const [totalClaimable, setTotalClaimable] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    if (account) {
      getTotalClaimable();
    }
  }, [account]);

  useEffect(() => {
    if (moonTokenContract && active) {
      getTotalClaimable();
    }
  }, [moonTokenContract, active]);

  const getTotalClaimable = async () => {
    const totalClaimable = await moonTokenContract.getTotalClaimable(account);
    setTotalClaimable(formatEther(totalClaimable));
  }

  const claimReward = async () => {
    try {
      setIsClaiming(true);
      const tx = await moonTokenContract.claimReward();
      const receipt = await tx.wait();

      const RewardClaimed = receipt.events?.filter((x) => {return x.event === "RewardClaimed"});
      const user = RewardClaimed[0].args[0];
      const reward = RewardClaimed[0].args[1];

      getTotalClaimable();
      setIsClaiming(false);

      const message = `${shorter(user)} has claimed the reward for ${formatUnits(reward, 18)} tokens`;
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });

    } catch (error) {
      setIsClaiming(false);
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <>
      {account? (
        <>
          <Row>
            <Col>
              <h2>Rewards</h2>
              <div>
                <p>{parseFloat(totalClaimable).toFixed(4)} $MOON</p>
                <Button
                  onClick={claimReward}
                  disabled={isClaiming}
                >
                  {isClaiming ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginRight: '7px',
                        marginTop: '-5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                  ) : null}
                  {isClaiming ? 'Claiming...' : 'Claim'}
                </Button>

                <Alert variant="info" style={{ marginTop: '10px' }}>
                  <b>NOTE:</b> $MOON can only be used to change your Alphie's name on Opensea by using 100 $MOON. There is no other utility to it, so if you don't want to change your Alphie's name yet, please don't "claim" it as it will cost you gas. We will create more utility for $MOON in the near future. Thanks
                </Alert>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}

export default RewardsComponent;