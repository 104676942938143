import React from "react";

import DepositSection from "../components/DepositSection";

function DepositPage(props) {
  return (
    <DepositSection
      textColor="white"
      variant="dark"
      title="Deposit"
      subtitle=""
    />
  );
}

export default DepositPage;
