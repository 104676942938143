import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { formatUnits, formatEther } from "@ethersproject/units";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import Spinner from "react-bootstrap/Spinner";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useMoonToken } from '../../hooks/useContract';
import { shorter } from "../../util/util";

import { getAccountByUserId, createAccountFromWeb } from '../../api';

import "./AccountCompleteStepComponent.scss";

const MOONTOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_MOONTOKEN_CONTRACT_ADDRESS;

function AccountCompleteStepComponent(props) {
  const { active, account } = useActiveWeb3React();
  const [moonTokenContract] = useState(useMoonToken(MOONTOKEN_CONTRACT_ADDRESS));
  const [totalClaimable, setTotalClaimable] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [wlWallet, setWlWallet] = useState('');
  const [alphieMoonAccount, setAlphieMoonAccount] = useState();
  const [createNewAccountStarted, setCreateNewAccountStarted] = useState(false);
  const [userId, setUserId] = useState();

  const { addToast } = useToasts();
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (account) {
      getTotalClaimable();
      init();
    }
  }, [account]);

  useEffect(() => {
    if (moonTokenContract && active) {
      getTotalClaimable();
    }
  }, [moonTokenContract, active]);

  const init = async () => {
    const userId = new URLSearchParams(search).get("userId")
    const { account: alphieMoonAccount } = await getAccountByUserId(userId);
    if (alphieMoonAccount) {
      setUserId(userId);
      setAlphieMoonAccount(alphieMoonAccount);
    }
  }

  const getTotalClaimable = async () => {
    const totalClaimable = await moonTokenContract.getTotalClaimable(account);
    setTotalClaimable(formatEther(totalClaimable));
  }

  const createAlphieMoonAccount = async () => {
    try {
      setIsCreating(true);

      await createAccountFromWeb(account, wlWallet, userId)
      const message = `Your account has been successfully created`;
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });

      // redirect
      history.push('/deposit');

      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <>
      {account? (
        <>
          {alphieMoonAccount ? (
            <>
              <Col md={12} className="text-center d-flex align-items-center justify-content-center" style={{ background: 'rgba(0,0,0,0.5)', padding: '20px', height: '400px', borderRadius: '8px' }}>
                <div>
                  <h2>
                    Almost There!
                  </h2>
                  <h5>
                    Only 1 more thing we need from you
                  </h5>
                  <div>
                    <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
                      <Form.Control
                        placeholder="Enter Whitelist Wallet"
                        value={wlWallet}
                        size="lg"
                        onChange={({target: { value }}) => setWlWallet(value)}
                        className="text-center"
                      />
                    </Form.Group>
                    <Button
                      onClick={createAlphieMoonAccount}
                      disabled={wlWallet.length === 0 || isCreating}
                      size="lg"
                      style={{
                        background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
                        fontWeight: '600',
                        border: '0px',
                        marginTop: '5px',
                      }}
                    >
                      {isCreating ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{
                            marginRight: '7px',
                            marginTop: '-5px',
                            position: 'relative',
                            top: '-2px',
                          }}
                        />  
                      ) : null}
                      {isCreating ? 'Creating...' : 'Create Account'}
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col md={12} className="text-center d-flex align-items-center justify-content-center" style={{ background: 'rgba(0,0,0,0.5)', padding: '20px', height: '400px', borderRadius: '8px' }}>
                <div>
                  <h2>
                    Something Wrong...
                  </h2>
                  <h5>
                    Invalid Request
                  </h5>
                </div>
              </Col>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

export default AccountCompleteStepComponent;