import React, { useEffect, useState } from "react";
import { formatUnits, formatEther, parseUnits } from "@ethersproject/units";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import Spinner from "react-bootstrap/Spinner";

import AddToMetaMaskButton from "./AddToMetaMaskButton";
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useMoonToken } from '../../hooks/useContract';
import { shorter } from "../../util/util";
import { getAccountByMoonWallet } from '../../api';

import "./DepositStepsComponent.scss";

const MOONTOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_MOONTOKEN_CONTRACT_ADDRESS;
const DEV_WALLET_ADDRESS = process.env.REACT_APP_DEV_WALLET_ADDRESS;
const ALPHIEMOON_BE_BASE_URL = process.env.REACT_APP_ALPHIEMOON_BE_BASE_URL;
const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;

const DISCORD_REDIRECT_URI = `${ALPHIEMOON_BE_BASE_URL}/api/auth/web/discord/callback`;
const DISCORD_LOGIN_URL = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${DISCORD_REDIRECT_URI}&response_type=code&scope=guilds%20identify`;

function DepositStepsComponent(props) {
  const { active, account } = useActiveWeb3React();
  const [moonTokenContract] = useState(useMoonToken(MOONTOKEN_CONTRACT_ADDRESS));
  const [totalClaimable, setTotalClaimable] = useState(0);
  const [moonCurrentBalance, setMoonCurrentBalance] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);
  const [alphieMoonAccount, setAlphieMoonAccount] = useState();
  const [moonToDeposit, setMoonToDeposit] = useState(0);

  const { addToast } = useToasts();

  useEffect(() => {
    if (account) {
      getTotalClaimable();
      getMOONCurrentBalance();
      init();
    }
  }, [account]);

  useEffect(() => {
    if (moonTokenContract && active) {
      getTotalClaimable();
      getMOONCurrentBalance();
    }
  }, [moonTokenContract, active]);

  const init = async () => {
    const { account: alphieMoonAccount } = await getAccountByMoonWallet(account);
    setAlphieMoonAccount(alphieMoonAccount);
  }

  const getTotalClaimable = async () => {
    const totalClaimable = await moonTokenContract.getTotalClaimable(account);
    setTotalClaimable(formatEther(totalClaimable));
  }

  const getMOONCurrentBalance = async () => {
    const moonCurrentBalance = await moonTokenContract.balanceOf(account);
    setMoonCurrentBalance(formatEther(moonCurrentBalance));
    setMoonToDeposit(formatEther(moonCurrentBalance));
  }

  const claimReward = async () => {
    try {
      setIsClaiming(true);
      const tx = await moonTokenContract.claimReward();
      const receipt = await tx.wait();

      const RewardClaimed = receipt.events?.filter((x) => {return x.event === "RewardClaimed"});
      const user = RewardClaimed[0].args[0];
      const reward = RewardClaimed[0].args[1];

      getMOONCurrentBalance();
      getTotalClaimable();
      setIsClaiming(false);

      const message = `${shorter(user)} has claimed the reward for ${formatUnits(reward, 18)} tokens`;
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });

    } catch (error) {
      setIsClaiming(false);
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  const transferMoonToDevWallet = async () => {
    try {
      setIsDepositing(true);
      const tx = await moonTokenContract.transfer(DEV_WALLET_ADDRESS, parseUnits(moonToDeposit, 18));
      await tx.wait();

      getMOONCurrentBalance();
      getTotalClaimable();
      setIsDepositing(false);
      
      setTimeout(() => {
        init();
      }, 3000);

      // TODO * Auto add credit to account once the tx is clear.

      const message = `You have transferred ${moonToDeposit} $MOON to our dev wallet`;
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });

    } catch (error) {
      setIsDepositing(false);
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  return (
    <>
      {account? (
        <>
          {!alphieMoonAccount ? (
            <Col md={12} className="text-center d-flex align-items-center justify-content-center" style={{ background: 'rgba(0,0,0,0.5)', padding: '20px', height: '400px', borderRadius: '8px' }}>
              <div>
                <h2>
                  You need to setup an account with us first!
                </h2>
                <h5>
                  Please login to your Discord account to get started.
                </h5>
                <a
                  href={DISCORD_LOGIN_URL}
                  rel="noopener noreferrer"
                >
                  <Button
                    size="lg"
                    style={{
                      background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
                      fontWeight: '600',
                      border: '0px',
                      marginTop: '5px',
                    }}
                  >
                    Login with Discord
                  </Button>
                </a>
              </div>
            </Col>
          ) : (
            <>
              <Col md={6}>
                <h2>Step 1</h2>
                <h5>Claim Your $MOON</h5>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicEmail">
                    <Form.Label>
                      {parseFloat(totalClaimable).toFixed(3)} $MOON <AddToMetaMaskButton />
                    </Form.Label>
                  </Form.Group>
                  
                  <Button
                    onClick={claimReward}
                    disabled={isClaiming || isDepositing}
                    style={{ marginRight: '10px' }}
                  >
                    {isClaiming ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          marginRight: '7px',
                          marginTop: '-5px',
                          position: 'relative',
                          top: '-2px',
                        }}
                      />
                    ) : null}
                    {isClaiming ? 'Claiming...' : 'Claim'}
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <h2>Step 2</h2>
                <h5>Deposit Your $MOON</h5>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      $MOON Amount you want to deposit:
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter $MOON Amount"
                      value={moonToDeposit}
                      onChange={({target: { value }}) => setMoonToDeposit(value)}
                      type="number"
                    />
                  </Form.Group>
                  <Button
                    onClick={transferMoonToDevWallet}
                    disabled={isClaiming || isDepositing}
                  >
                    {isDepositing ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                          marginRight: '7px',
                          marginTop: '-5px',
                          position: 'relative',
                          top: '-2px',
                        }}
                      />
                    ) : null}
                    {isDepositing ? 'Processing...' : 'Deposit'}
                  </Button>
                  
                </div>
              </Col>

              <Col md={12} className="text-center mt-5 mb-4">
                <h5>Your Shop Balance</h5>
                <h5 style={{
                  maxWidth: '50%',
                  margin: '0 auto',
                  fontSize: '30px',
                  background: 'white',
                  color: 'black',
                  padding: '10px 10px',
                  borderRadius: '8px',
                  boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)',
                }}>
                  <span
                    style={{
                      background: '-webkit-linear-gradient(63deg, #440BD4 0%, #FF2079 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: '600',
                    }}
                  >
                    {parseFloat(alphieMoonAccount.moonBalance).toFixed(3)} $MOON 
                  </span>
                </h5>
              </Col>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

export default DepositStepsComponent;