import React from "react";

import MintSection from "../components/MintSection";
import ContractAddressSection from "../components/ContractAddressSection";

function IndexPage(props) {

  return (
    <>
      <MintSection
        textColor="white"
        variant="dark"
        title="HEY HOMIE!"
        subtitle="I know! Everyone wants you to hold but we want you to transfer, resell, trade, give it away 😄 Transfer your homie to reveal their true identity! and get a chance to win a blue-chip NFT"
        type="public"
      />
    </>
  );
}

export default IndexPage;
