import React, { useEffect, useState } from "react";
import { formatEther, parseEther, formatUnits } from "@ethersproject/units";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import tada from 'react-animations/lib/tada';
import flash from 'react-animations/lib/flash';
import { useMediaQuery } from 'react-responsive';
import { useToasts } from 'react-toast-notifications';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT } from '../../hooks/useContract';
import { Link } from "../../util/router";

import alphiesRandomGif from "../../images/alphies-random.gif";
import alphiesRandomQuestionGif from "../../images/alphies-random2.gif";
import alphieWelcomeGif from "../../images/alphie-welcome.gif";

import "./MintComponent.scss";

const ALPHIE_BASE_URL = process.env.REACT_APP_ALPHIE_BASE_URL;
const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
const tadaAnimation = keyframes`${tada}`;
const flashAnimation = keyframes`${flash}`;

const TadaImage = styled(Image)`
  animation: 1s ${tadaAnimation};
  animation-iteration-count: infinite;
`;

const FlashDiv = styled.div`
  animation: 1s ${flashAnimation};
  animation-iteration-count: 1;
`;

function MintComponent(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { width, height } = useWindowSize()
  const { library, active, account } = useActiveWeb3React();
  const [contract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [currentPrice, setCurrentPrice] = useState(0);
  const [totalMintableTokens, setTotalMintableTokens] = useState(0);
  const [maxTokens, setMaxTokens] = useState(0);
  const [maxTokensPerPurchase, setMaxTokensPerPurchase] = useState(0);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [isMintedConfetti, setIsMintedConfetti] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [mintedTokens] = useState([]);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [signature, setSignature] = useState();
  const [type, setType] = useState();
  const [ETHBalance, setETHBalance] = useState(0);

  const [mintAmount, setMintAmount] = useState(1);
  const [saleActive, setSaleActive] = useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    if (contract && active) {
      getCurrentPrice();
      getTotalMintableTokens();
      getInitialGenesisMaxTokens();
      getMintStatus();
    }
  }, [contract, active]);

  useEffect(() => {
    if (account) {
      getAccountEthBalance();
      checkWhitelist();
      getMintStatus();
    }
  }, [account]);
  
  const getMintStatus = async () => {
    if (props.type === 'public') {
      const publicActive = await contract.publicMintActive();
      setSaleActive(publicActive);
    } else if (props.type === 'private') {
      const preActive = await contract.preMintActive();
      setSaleActive(preActive);
    }
  }

  const fetchSignature = async () => {
    const url = `${ALPHIE_BASE_URL}/api/v0/whitelist/${account}`
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${WL_API_KEY}`
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    return response.json();
  }

  const checkWhitelist = async () => {
    let isWhitelisted = false;
    let mintAmount;
    if (props.type === 'public') {
      const maxTokens = formatUnits(await contract.PUBLICSALE_MAX_TOKENS_PER_PURCHASE(), 0);  
      setMaxTokensPerPurchase(parseInt(maxTokens, 10));
      isWhitelisted = true;
    } else if (props.type === 'private') {
      const { signature, type } = await fetchSignature();
      if (!signature) {
        isWhitelisted = false;
        setSignature();
        setType();
        setIsWhitelisted(isWhitelisted)
        setMaxTokensPerPurchase(0);
        return;
      }
      ([isWhitelisted, mintAmount] = await contract.isWhitelisted(type, signature));
      
      setSignature(signature);
      setType(type);
      setMaxTokensPerPurchase(parseInt(formatUnits(mintAmount, 0), 10));
    }
    
    setIsWhitelisted(isWhitelisted)
  }

  const getAccountEthBalance = async () => {
    const accountETHBalance = await library.getBalance(account);
    setETHBalance(formatEther(accountETHBalance));
  }
  
  const getCurrentPrice = async () => {
    const price = formatEther(await contract.GENESIS_PRICE());
    setCurrentPrice(price);
  }

  const getTotalMintableTokens = async () => {
    const initialGenesisMaxTokens = formatUnits(await contract.MAX_TOTAL_SUPPLY(), 0);
    const currentGenesisCount = formatUnits(await contract.genesisCount(), 0);
    setTotalMintableTokens(initialGenesisMaxTokens - currentGenesisCount);
  }

  const getInitialGenesisMaxTokens = async () => {
    const initialGenesisMaxTokens = formatUnits(await contract.MAX_TOTAL_SUPPLY(), 0);
    setMaxTokens(initialGenesisMaxTokens);
  }

  const mintNFT = async () => {
    setIsMintLoading(true);
    setIsMinted(false);

    const cost = (currentPrice * mintAmount).toFixed(3);
    const price = parseEther(cost.toString()).toString();

    let overrides = {
      value: price,
    };

    try {
      let tx;

      if (props.type === 'private') {
        tx = await contract.mintPre(mintAmount, type, signature, overrides);
      } else {
        tx = await contract.mintPublic(mintAmount, overrides);
      }

      const receipt = await tx.wait();

      const whaleMinted = receipt.events?.filter((x) => {return x.event === "WhaleMinted"});
      const WhalesMinted = receipt.events?.filter((x) => {return x.event === "WhalesMinted"});

      
      whaleMinted.forEach(w => {
        const tokenId = w.args[1];
        mintedTokens.push(formatUnits(tokenId, 0));
      });

      setIsMintLoading(false);
      setIsMinted(true);
      setIsMintedConfetti(true);


      const amount = WhalesMinted[0].args[1];

      addToast(`NICEEEEEEE! You got ${formatUnits(amount, 0)} AlphieWhales!`, {
        appearance: 'info',
        autoDismiss: true,
      });

      await getTotalMintableTokens();
    } catch (error) {
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      })

      setIsMintLoading(false);
    }
  }

  const handleMintAmountChange = (event) => {
    setMintAmount(event.target.value);
  }

  return (
    <>
      {isMintedConfetti ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={600}
          recycle={false}
          onConfettiComplete={() => setIsMintedConfetti(false)}
        />
      ): null}

      {isMinted ? (
        <Col md={8} className="text-center offset-md-2">
          <div>
            <Image src={alphieWelcomeGif} className="img-fluid" rounded style={{ boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)', }} />
            <h4 style={{ marginTop: isMobile ? '25px' : '50px' }}>Niceee!! You just got {mintAmount} x {mintAmount === 1 ? 'AlphieWhale': 'AlphieWhales'}!</h4>
            <Link to="/wallet" style={{ color: 'inherit' }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '15px', fontSize: isMobile ? '25px' : '40px' }}
                variant={props.buttonColor}
                size="lg"
              >
                Wallet
              </Button>
            </Link>
          </div>
        </Col>
      ) : (
        <>
          <Col xs={12} className="text-center mb-5">
            <div>
              {isMintLoading ? (
                <TadaImage src={alphiesRandomGif} rounded width={300} className="img-fluid" style={{ boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)' }} />
              ) : (
                <FlashDiv className="text-center img-fluid" style={{ position: 'relative', margin: '0 auto', width: '300px', height: '300px'}}>
                  <h5
                    style={{
                      position: 'absolute',
                      right: '-20px',
                      top: '2px',
                      transform: 'rotate(25deg)',
                      background: 'white',
                      color: 'black',
                      padding: '5px 10px',
                      borderRadius: '8px',
                      boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)',
                    }}>
                      <span
                        style={{
                          background: '-webkit-linear-gradient(63deg, #440BD4 0%, #FF2079 100%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: '600',
                        }}
                      >
                        {props.type === 'private' ? 'Presale' : 'Public'}
                      </span>
                    </h5>
                  <Image src={alphiesRandomQuestionGif} rounded width={300} className="img-fluid" style={{ boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)', }} />
                </FlashDiv>
              )}
            </div>
          </Col>
          <Col className="MintComponent text-center mb-4">
            <h5>Your ETH Balance: {parseFloat(ETHBalance).toFixed(3)}</h5>
            <h4>Mint Price: {mintAmount} {mintAmount === 1 ? 'Alphie' : 'Alphies'} * {currentPrice} ETH = 
              <span 
                style={{
                  background: 'white',
                  color: 'black',
                  padding: isMobile ? '2px 6px' : '5px',
                  margin: '5px',
                  borderRadius: '8px',
                }}>
                  <span
                    style={{
                      background: '-webkit-linear-gradient(63deg, #440BD4 0%, #FF2079 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: '600',
                    }}
                  >

                  {(currentPrice * mintAmount).toFixed(3)} ETH
                  </span>
                </span>
            </h4>

            {saleActive ? (
              <>
                {totalMintableTokens === 0 ? (
                  <div
                    className="text-center"
                    style={{
                      width: '250px',
                      margin: '20px auto 10px',
                      background: 'black',
                      padding: '20px 10px',
                      borderRadius: '5px',
                    }}>
                    <h1 style={{ margin: '0px' }}>SOLD OUT</h1>
                  </div>
                ) : (
                  <>
                    {maxTokensPerPurchase > 0 ? (
                      <div className="text-center" style={{ width: '100px', margin: '20px auto 10px' }}>
                        <Form.Control
                          as="select"
                          value={isWhitelisted ? mintAmount: 'Amount'}
                          disabled={!isWhitelisted}
                          onChange={handleMintAmountChange}
                          className="text-center"
                        >
                          <option disabled>Amount</option>
                          {[...Array(maxTokensPerPurchase)].map((x, i) =>
                            <option value={i + 1} key={i + 1}>{i + 1}</option>
                          )}
                        </Form.Control>
                      </div>
                    ) : null}
    
                    {isWhitelisted ? (
                      <>
                        {maxTokensPerPurchase > 0 ? (
                          <Button
                            style={{ marginTop: '10px', marginBottom: '15px', fontSize: isMobile ? '25px' : '40px' }}
                            variant={props.buttonColor}
                            size="lg"
                            onClick={mintNFT}
                            disabled={isMintLoading}
                          >
                            {isMintLoading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{
                                  marginRight: '7px',
                                  marginTop: '-10px',
                                  position: 'relative',
                                  top: '-4px',
                                  width: '40px',
                                  height: '40px',
                                }}
                              />
                            ) : null}
                            {isMintLoading ? 'Minting...' : 'Mint Now!'}
                          </Button>
                        ) : (
                          <Button
                            style={{ marginTop: '10px', marginBottom: '25px', fontSize: '40px' }}
                            variant={props.buttonColor}
                            size="lg"
                            onClick={mintNFT}
                            disabled
                          >
                            You already minted
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        style={{ marginTop: '10px', marginBottom: '25px', fontSize: '25px' }}
                        variant={props.buttonColor}
                        size="lg"
                        onClick={mintNFT}
                        disabled
                      >
                        You are not on the Whalelist
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : (
              <Button
                style={{ marginTop: '10px', marginBottom: '25px', fontSize: '25px' }}
                variant={props.buttonColor}
                size="lg"
                onClick={mintNFT}
                disabled
              >
                Sale has not started yet
              </Button>
            )}


            <h6>Remaining: {totalMintableTokens}/{maxTokens}</h6>
          </Col>
        </>
      )}
    </>
  );
}

export default MintComponent;