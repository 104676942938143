import React, { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Skeleton from '@mui/material/Skeleton';

import SectionHeader from "../SectionHeader";
import DepositStepsComponent from './DepositStepsComponent';

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useNFT } from '../../hooks/useContract';
import { Link } from "../../util/router.js";

import "./DepositComponent.scss";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function WalletComponent(props) {
  const { active, account } = useActiveWeb3React();
  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));
  const [alphies, setAlphies] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (nftContract && active && account) {
      getAlphiesByAccount();
    }
  }, [nftContract, active, account]);


  const fetchNFTFromTokenURI = async (id) => {
    try {
      const tokenURI = await nftContract.tokenURI(id);
      const response = await fetch(tokenURI, { method: "GET", mode: 'cors', headers: { 'Content-Type': 'application/json',}})
      return response.json();
    } catch (error) {
      console.log('error=====>>>>>', error);
    }
  }

  const getAlphiesByAccount = async () => {
    const tokenIds = await nftContract.walletOfOwner(account);
    setTotalLength(tokenIds.length);
    const alphies = [];
    for (let tokenId of tokenIds) {
      const id = formatUnits(tokenId, 0);
      const token = await fetchNFTFromTokenURI(id);
      alphies.push({
        ...token,
        id,
      });
      timeout(300);
    }

    setAlphies(alphies);
    setIsPageLoading(false);
  }

  return (
    <>
      {account ? (
        <Container className="text-center">
          <SectionHeader
            className="header-font above-fold-header"
            title="$MOON"
            subtitle="Deposit $MOON to Your AlphieMoon Shop Account"
            size={1}
          />
          <Row className="text-left align-items-center" style={{ padding: '0px'}}>
            <DepositStepsComponent />
          </Row>
        </Container>
      ) : (
        <Col xs={12} className="text-center">
          <h1>You're not allowed to be here</h1>
        </Col>
      )}

    </>
  );
}

export default WalletComponent;