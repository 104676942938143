import React from "react";

import WalletSection from "../components/WalletSection";

function WalletPage(props) {
  const { id } = props.match.params;

  return (
    <WalletSection
      textColor="white"
      variant="dark"
      title="Wallet"
      subtitle=""
    />
  );
}

export default WalletPage;
