import React, { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Container from "react-bootstrap/Container";
import { useToasts } from 'react-toast-notifications';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { formatEther } from "@ethersproject/units";
import { useMediaQuery } from 'react-responsive';

import { useNFT } from '../../../hooks/useContract';

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

function ChangeNameModal({ open, onClose, whaleId }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : 400,
    backgroundColor: 'white',
    // border: '4px solid #a616a4',
    boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.15)',
    borderRadius: '12px',
  };

  const [nftContract] = useState(useNFT(NFT_CONTRACT_ADDRESS));

  const [whaleName, setWhaleName] = useState('');
  const [isChangingName, setIsChanginName] = useState(false);
  const [nameChangePrice, setNameChangePrice] = useState(0);

  const { addToast } = useToasts();

  useEffect(() => {
    if(nftContract) {
      getCurrentWhaleName();
      getNameChangePrice();
    }
  }, [nftContract]);

  const getCurrentWhaleName = async () => {
    const whaleName = await nftContract.whaleNames(whaleId);
    setWhaleName(whaleName);
  }

  const getNameChangePrice = async () => {
    const nameChangePrice = await nftContract.NAME_CHANGE_PRICE();
    setNameChangePrice(formatEther(nameChangePrice));
  }

  const onChangeName = async (e) => { 
    e.preventDefault();
    setIsChanginName(true);

    try{
      const tx = await nftContract.changeName(whaleId, whaleName);
      await tx.wait();

      setWhaleName(whaleName);
      setIsChanginName(false);
      onClose();

      const message = `Whale #${whaleId} name has been changed ${whaleName}`;
      addToast(message, {
        appearance: 'info',
        autoDismiss: true,
      });
    } catch (error) { 
      setIsChanginName(false);
      addToast(error.reason ? error.reason : error.message , {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Container style={style}>
        <Row>
          <Col xs={12} className="text-center" style={{ padding: '30px', borderRadius: '8px 8px 0px 0px', background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)' }}>
            <h4 style={{ color: 'white' }}>
              <u>
                Change Alphie Name
              </u>
            </h4>
            <p style={{ color: 'white', marginBottom: '0px' }}>Cost: {nameChangePrice} $MOON</p>
          </Col>
          <Col md={12} className="text-center" style={{ padding: '30px 50px', cursor: 'pointer', border: '1px solid #ffecf3', borderRadius: '0 0px 12px 12px', }}>
            <div style={{ marginBottom: '20px' }}>
              <Form onSubmit={onChangeName}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    placeholder="Whale Name"
                    className="text-center"
                    value={whaleName}
                    onChange={({target: { value }}) => setWhaleName(value)}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  disabled={isChangingName || whaleName.length === 0}
                >
                  {isChangingName ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginRight: '7px',
                        marginTop: '-10px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                ) : null}
                  {isChangingName ? 'Updating...' : 'Update Name'}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default ChangeNameModal;
