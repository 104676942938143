import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import pulse from 'react-animations/lib/pulse';
import Countdown from 'react-countdown';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useMediaQuery } from 'react-responsive';

import Section from "./Section";
import MintComponent from './MintComponent';

import WalletModal from "../WalletModal";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';

import alphieWhalesGang from "../../images/alphie-whales-1.png";
import metaverseBG from "../../images/metaverseBG.jpg";

const pulseAnimation = keyframes`${pulse}`;

const PulseButton = styled(Button)`
  animation: 3s ${pulseAnimation};
  animation-iteration-count: infinite;
`;

function MintSection(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { width } = useWindowSize()
  const { active, error } = useActiveWeb3React();

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  const CountdownDiv = styled.div`
    display: inline-block;
    margin: ${width < 576 ? '5px' : '10px' };
    padding: ${width < 576 ? '10px' : '20px' };
    border-radius: 5px;
    border: 2px solid black;
  `;

  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Section
        bg="white"
        textColor="white"
        size={props.size}
        bgImage={metaverseBG}
        bgImageOpacity={active ? 0.08 : 0.6}
      >
        <Container fluid>
          {active ? (
            <Row
              className="align-items-center" 
              style={{
                padding: `140px 0px ${active ? '70px' : '0px'}`,
                background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
                paddingBottom: '0px !important',
              }}
            >
              <MintComponent {...props} />
            </Row>
          ) : (
            <>
              <Row
                className="align-items-center"
                style={{
                  paddingBottom: '0px !important',
                  background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
                }}
              >
                <Container>
                  <Row>
                    <Col
                      xs={12} 
                      className="text-center text-black"
                      style={{
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                        overflow: isMobile ? 'hidden' : 'inherit',
                      }}
                    >
                        <div
                          style={{
                            // marginTop: isMobile ? '200px' : '250px',
                            marginTop: 'auto',
                            // flexDirection: 'column',
                            // justifyContent: 'center',
                          }}
                        >
                        </div>
                      <div>
                          <PulseButton
                            variant={props.buttonColor}
                            onClick={toggleModal}
                            size="lg"
                            style={{
                              background: 'white',
                              color: 'black',
                              fontSize: '30px',
                              fontWeight: '500',
                            }}
                          >
                            Connect Wallet
                          </PulseButton>
                          {error && error.name === 'UnsupportedChainIdError' && (
                            <h6 style={{ marginTop: '10px', color: 'red' }}>
                              <u>
                                Please change to Mainnet!
                              </u>
                            </h6>
                          )}
                      </div>
                      <Image
                        src={alphieWhalesGang}
                        className="img-fluid text-center"
                        style={{
                          transform: isMobile ? 'scale(1.8, 1.8)' : 'scale(1.3, 1.3)' ,                          
                          marginTop: 'auto',
                          position: 'relative',
                          top: isMobile ? '-40px' : '-20px',
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </Row>
            </>
          )}
        </Container>
      </Section>
    </>
  );
}

export default MintSection;
