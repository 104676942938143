import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SectionHeader from "../SectionHeader";
import Section from "./Section";
import DepositComponent from './DepositComponent';

import WalletModal from "../WalletModal";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';

function DepositSection(props) {
  const { active, error } = useActiveWeb3React();

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        style={{
          paddingBottom: '0px !important',
          background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
        }}
      >
        <Container style={{ padding: '100px 35px 0px' }}>
          {active ? (
            <>
              <Row className="align-items-center" style={{ padding: '70px 0px'}}>
                <DepositComponent />
              </Row>
            </>
          ) : (
            <>
              <SectionHeader
                className="text-center header-font above-fold-header"
                title={props.title}
                subtitle={props.subtitle}
                size={1}
              />
              <Row className="align-items-center" style={{ padding: '0px 0px 70px'}}>
                <>       
                  <Col xs={12} className="text-center">
                      <div>
                        <Button variant={props.buttonColor} onClick={toggleModal} size="lg">
                          Connect Wallet
                        </Button>
                        {error && error.name === 'UnsupportedChainIdError' && (
                          <h6 style={{ marginTop: '10px', color: 'red' }}>
                            <u>
                              Please change to Mainnet!
                            </u>
                          </h6>
                        )}
                      </div>
                  </Col>
                </>
              </Row>
            </>
          )}
        </Container>
      </Section>
    </>
  );
}

export default DepositSection;
