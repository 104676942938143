import React, { useEffect, useState } from "react";
import { formatUnits, formatEther } from "@ethersproject/units";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import Spinner from "react-bootstrap/Spinner";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useMoonToken } from '../../hooks/useContract';
import { shorter } from "../../util/util";
import { connectorsConfig } from '../../config/constant';

import "./DepositStepsComponent.scss";

const MOONTOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_MOONTOKEN_CONTRACT_ADDRESS;
const MOON_SYMBOL = 'MOON';
const MOON_DECIMALS = 18;

function AddToMetaMaskButton(props) {
  const { icon: Icon } = connectorsConfig[0];

  const addMoonToMetaMask = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: MOONTOKEN_CONTRACT_ADDRESS, // The address that the token is at.
            symbol: MOON_SYMBOL, // A ticker symbol or shorthand, up to 5 chars.
            decimals: MOON_DECIMALS, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Button
        onClick={addMoonToMetaMask}
        size="sm"
      >
        <Icon width="20px" /> Add to MetaMask
      </Button>
    </>
  );
}

export default AddToMetaMaskButton;