import React, { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import SectionHeader from "../SectionHeader";
import Section from "./Section";
import ChangeNameModal from "./ChangeNameModal";

import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { shorter } from "../../util/util";


const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
let OPENSEA_BASE_URL = 'https://testnets.opensea.io';

let OPENSEA_SUB_DIR = '';

if (process.env.REACT_APP_CHAIN_ID === '80001') {
  OPENSEA_SUB_DIR = 'mumbai';
}

if (process.env.NODE_ENV === 'production') {
  OPENSEA_BASE_URL = 'https://opensea.io';
}

function AlphaWhaleSection(props) {
  const { active, error, account } = useActiveWeb3React();
  const { contract, id } = props;
  const [token, setToken] = useState(0);
  const [tokenOwner, setTokenOwner] = useState();
  const [tokenMaxTransferCount, setTokenMaxTransferCount] = useState(0);
  const [currentTokenTransferCount, setCurrentTokenTransferCount] = useState(0);
  
  const [errorMsg, setErrorMsg] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    fetchNFTFromTokenURI();
    setModalState(!modalState);
  }

  useEffect(() => {
    if (contract) {
      init();
      fetchNFTFromTokenURI();
    }
  }, [contract]);

  const fetchNFTFromTokenURI = async () => {
    try {
      const tokenURI = await contract.tokenURI(id);
      const response = await fetch(tokenURI, { method: "GET", mode: 'cors', headers: { 'Content-Type': 'application/json',}})
      const data = await response.json();
      setToken(data);
    } catch (error) {
      setErrorMsg(error);
    }
  }

  const  openChangeNameModal = async () => {
    setModalState(!modalState);
  }

  const init = async () => {
    try {
      setTokenOwner(await contract.ownerOf(id));
      setTokenMaxTransferCount(formatUnits(tokenMaxTransferCount, 0));
      setCurrentTokenTransferCount(formatUnits(currentTokenTransferCount, 0));

      setIsPageLoading(false);
    } catch (error) {
      setErrorMsg(error);
      setIsPageLoading(false);
    }
  }

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      style={{
        paddingBottom: '0px !important',
        background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isPageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container style={{ padding: '120px 35px 0px' }}>
        {!error && !errorMsg ? (
          <>
            {modalState ? (
              <ChangeNameModal open={modalState} onClose={toggleModal} whaleId={id} />
            ) : null}

            <Row className="align-items-center pb-5">
              <Col md={5}>
                {!isPageLoading ? (
                  <Image src={token.image} rounded style={{ boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.3)' }} className="img-fluid" />
                ) : (
                  <Skeleton variant="rectangular" width="100%" height={400} />
                )}
              </Col>
              <Col md={7} className="mt-4 mt-md-0">
                {!isPageLoading ? (
                  <>
                    <SectionHeader
                      className="header-font above-fold-header"
                      title={token.name}
                      size={1}
                    /> 
                    <p style={{ marginTop: '-35px'}}>Owned by <b>{account === tokenOwner ? 'you' : shorter(tokenOwner)}</b></p>

                    {token.attributes && token.attributes.length > 0 ? (
                      <>
                        <h6><u>Attributes</u></h6>
                        {token.attributes && token.attributes.map(attribute => (
                          <Chip 
                            key={attribute.trait_type}
                            style={{ textTransform: 'capitalize', background: 'white', marginTop: '10px', marginRight: '10px' }}
                            label={`${attribute.trait_type}: ${attribute.value}`} 
                          />
                        ))}
                      </>
                    ) : null}

                    <br />
                    <br />

                    {active && account === tokenOwner && !error ? (
                      <>
                        <Button onClick={openChangeNameModal}>Change Name</Button>
                        <a
                          href={`${OPENSEA_BASE_URL}/assets/${OPENSEA_SUB_DIR}/${NFT_CONTRACT_ADDRESS}/${id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button style={{ marginLeft: '10px' }}>Sell it on Opensea</Button>
                        </a>
                      </>
                    ) : (
                      <>
                        <a 
                          href={`${OPENSEA_BASE_URL}/assets/${OPENSEA_SUB_DIR}/${NFT_CONTRACT_ADDRESS}/${id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button>Buy it on Opensea</Button>
                        </a>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h1>
                      <Skeleton animation="wave" height={100} width="90%" />
                    </h1>
                    <p>
                      <Skeleton animation="wave" width="30%" />
                    </p>
                    <h4>
                      <Skeleton animation="wave" width="50%" />
                    </h4>
                    <h4>
                      <Skeleton animation="wave" width="60%" />
                    </h4>
                    <h4>
                      <Skeleton animation="wave" width="60%" />
                    </h4>
                  </>
                )}
                
              </Col>
            </Row>
          </>
        ) : (
          <Row className="align-items-center" style={{ padding: "70px 0px"}}>
            <Col md={12}>
              <h1>Something Wrong</h1>
            </Col>
          </Row>
        )}
      </Container>
    </Section>
  );
}

export default AlphaWhaleSection;
