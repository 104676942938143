import React from "react";
import { Element } from "react-scroll";

import HeroSection from "../components/HeroSection";
import ContractAddressSection from "../components/ContractAddressSection";
import AboutSection from "./../components/AboutSection";
import HowToPlaySection from "./../components/HowToPlaySection";
import RewardSection from "./../components/RewardSection";
// import RaritySection from "./../components/RaritySection";
import RoadmapSection from "./../components/RoadmapSection";
import FaqSection from "./../components/FaqSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

import logoWhite from "../images/frankhomies-logo-white.png";

function IndexPage(props) {

  return (
    <>
      <Element name="herosection">
        <HeroSection
          textColor="white"
          variant="dark"
          title="HEY HOMIE!"
          subtitle="I know! Everyone wants you to hold but we want you to transfer, resell, trade, give it away 😄 Transfer your homie to reveal their true identity! and get a chance to win a blue-chip NFT"
        />
      </Element>

      <Element name="aboutsection">
      <AboutSection
        bg="black"
        textColor="white"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="AlphieWhales??"
        subtitle="FrankHomies come in a wide variety of shapes and colors"
        buttonText="Get Started"
        buttonColor="primary" 
      />
      </Element>

      <Element name="howtoplaysection">
        <HowToPlaySection
          bg="blue"
          textColor="light"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="HOW TO PLAY"
          subtitle="Want to try out your luck and get a chance to reveal your NFT and get a chance some awesome NFTs?"
          buttonText="Get Started"
          buttonColor="primary"
          buttonPath="/pricing"
        />
      </Element>
      <Element name="rewardsection">
        <RewardSection
          bg="red"
          textColor="light"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="HOW TO PLAY"
          subtitle="FrankHomies come in a wide variety of shapes and colors"
          buttonText="Get Started"
          buttonColor="primary"
          buttonPath="/pricing"
        />
      </Element>

      <Element name="roadmapsection">
        <RoadmapSection
          bg="dark"
          textColor="light"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="WHALEMAP"
          logo={logoWhite}
        />
      </Element>

      <Element name="teamsection">
        <TeamBiosSection
          bg="red"
          textColor="light"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="TEAM"
          subtitle=""
        />
      </Element>

      <Element name="faqsection">
        <FaqSection
          bg="purple"
          textColor="light"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="FAQs"
          subtitle=""
        />
      </Element>
      {/* <ContractAddressSection
        bg="black"
        textColor="white"
      /> */}
    </>
  );
}

export default IndexPage;
